import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../../components/LayoutSignup';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import Cell from '../../components/Cell';
import P from '../../components/P';
import H3 from '../../components/H3';

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 0 0 ${({ theme }) => theme.colors.blue};
  }
`;

const SignupPage = () => (
  <Layout>
    <SEO title="Signup" />

    <Grid py="baseUp8" halign="center">
      <Cell size={{ mobile: 1, smallTablet: 1 / 2 }} px="baseUnit">
        <H3 textAlign="center" mb="baseUnit">
          Välkommen!
        </H3>
        <P textAlign="center">Vi kontaktar dig inom kort!</P>
        <P textAlign="center">
          <BackLink to="/">Tillbaka till startsidan</BackLink>
        </P>
      </Cell>
    </Grid>
  </Layout>
);

export default SignupPage;
